import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import Input from "components/shared/Inputs/Inputs";
import Button from "components/shared/Button/Button";
import SearchTable from "components/shared/SearchTable/SearchTable";
import { Col, Card, CardBody, Container, Row } from "reactstrap"

import api from "../../../services/api";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import AuthService from "../../../services/Auth.service";
import Select from "react-select";


const EquipamentoLora = () => {
  const initialState = {
    maker: "",
    desc: "",
    model: "",
    number: "",
  };

  const Empresa = {
    OID_EMPRESA: "",
    NM_RAZAO_SOCIAL: "",
   
  };

  const user = AuthService.getCurrentUser();

  let navigate = useNavigate();

    let checkempresa = null;
    let DS_STATUS = null;
    if(user != null){
      
      DS_STATUS = user.user.DS_STATUS
    if(user.empresa[0] != undefined){
      checkempresa = user.empresa[0].OID_EMPRESA
    

    }
    }


    const [selectedNumber, setSelectedNumber] = useState(null);

    const [showPopup, setShowPopup] = useState(false);

  const handleButtonClick = (number) => {
     // Prevent default form submission
    setSelectedNumber(number);
    setShowPopup(true);
  };

  const handleConfirm = () => {
    // Perform your function logic here
    console.log('Function executed with number:', selectedNumber);
    setShowPopup(false); 

    let bodyreg = {
      
      OID_EQUIPAMENTO_LORA: selectedNumber,
      
    }

    api.post("/registros/filter", bodyreg).then(response => {
      console.log(response.data.length+"len")
      if (response.data.length > 0) {
       
        Swal.fire({
          icon: "warning",
          title: "O módulo está vinculado a um kit; antes de deletar o módulo, remova o vínculo.",
        });
      } 
      else{
        api.delete(`/equipmentslora/${selectedNumber}`).then(() => {
          Swal.fire({
            icon: "success",
            title: "Módulo deletado!",
          })
            .then(() => {
              navigate("/cadastro/modulo/equipamentolora");
              window.location.reload(true)
            })
            .catch(() => {
              Swal.fire({
                icon: "warning",
                title: "Dados Inválidos!",
              });
            });
        });

       
      }
    })


    // api
    //   .delete(`/registrosportas/kit/${selectedNumber}`)
    //   .then((response) => {
    //     Swal.fire({
    //       icon: "success",
    //       title: "Medidor deletado!",
    //     }).then(() => {
    //       window.location.reload(true)
    //     });
    //   })
    //   .catch(function (error) {
    //     Swal.fire({
    //       icon: "warning",
    //       title: "Falha na deleção, tente novamente",
    //     });
    //   });



    // Close the popup after confirming
  };

  const handleCancel = () => {
    setShowPopup(false); // Close the popup without executing the function
  };


  const [search, setSearch] = useState(initialState);
  const [makers, setMakers] = useState([]);
  const [values, setValues] = useState([]);

  const [maker, setMaker] = useState([]);

  const [showList, setShowList] = useState(false);
  let [lc, setLc] = useState (Empresa);
  const [condos, setCondo] = useState([]);

  const fetchCondo = () => {
    let condo = [{ id: "",  condo: "Selecione..." }];
    api.get("/empresa/").then((response) => {
      response.data.map((elem) => {
        condo.push({
          value: elem.OID_EMPRESA,
          label: elem.NM_RAZAO_SOCIAL,
          
        });
      });
      setCondo(condo);
    });
  };

  if(DS_STATUS == 10){
    useEffect(() => {
      fetchCondo();
    }, []);
  }

  const onChangeCondo = (prop) => (event) => {
    
    setLc({ ...lc, [prop]: event.target.value});
    
  };

  const onChangeHandlerCond = (selectedOption) => {

    if (selectedOption === null) {
      setLc(null);
    }
   else if (selectedOption.value) {
    setLc(selectedOption);
    }
  };

  const onChangeHandlerMaker = (selectedOption) => {

    if (selectedOption === null) {
      setMaker(null);
    }
   else if (selectedOption.value) {
    setMaker(selectedOption);
    }
  };



  const fetchMakers = () => {
    let maker = [{ id: "", maker: "Selecione..." }];
    api.get("/makerslora").then((response) => {
      response.data.map((elem) => {
        maker.push({
          value: elem.OID_FABRICANTE_LORA,
          label: elem.DS_FABRICANTE_LORA,
        });
      });
      setMakers(maker);
    });
  };

  const onChangeHandler = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
    console.log(search.maker)
  };

  const submitHandler = (event) => {

    if(lc){
      checkempresa = lc.value;
    }

    console.log(checkempresa)

    event.preventDefault();
    let body = {
      OID_FABRICANTE_LORA: maker.value,
      DS_DESCRICAO_EQUIPAMENTO: search.desc,
      DS_MODELO_EQUIPAMENTO: search.model,
      NR_EQUIPAMENTO: search.number,
      OID_EMPRESA: checkempresa,
    };

    api
      .post("/equipmentslora/filter", body)
      .then((response) => {
        let arr = response.data;
        if (arr.length === 0) {
          Swal.fire({
            icon: "warning",
            text: "Nenhum resultado encontrado, refaça a sua busca!",
          });
          setShowList(false);
          return;
        } else {
          Swal.fire({
            icon: "success",
            timer: 500,
          });
          setValues(formatArray(arr));
          setSearch(initialState);
          setShowList(true);
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "warning",
          text: "Preencha ao menos UM campo para realizar a busca",
        });
        setShowList(false);
      });
  };

  const formatArray = (data) => {
    let aux = [];
    makers.map((maker) => {
      data.map((elem2) => {
        if (maker.value === elem2.OID_FABRICANTE_LORA) {
          aux.push({
            id: elem2.OID_EQUIPAMENTO_LORA,
            makerId: maker.value,
            maker: maker.label,
            desc: elem2.DS_DESCRICAO_EQUIPAMENTO,
            model: elem2.DS_MODELO_EQUIPAMENTO,
            number: elem2.NR_EQUIPAMENTO,
          });
        }
      });
    });
    return aux;
  };

  useEffect(() => {
    fetchMakers();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <label id="component-title">Pesquisa | Módulos Lora</label>
        <form className="component-form container" style={{ margin: "0px" }}>
          <div className="container">
          {DS_STATUS == 10?
            <div className="row">


                  <div className="col-6" style={{ textAlign: "start" }}>
                    <label
                      htmlFor="Condominio"
                      className="mt-1"
                    >
                      Condomínio
                    </label>
                    <Select
                     
                      label="Condominio"
                      id="inlineFormSelectPrefc"
                    placeholder="Selecione..."
                    onChange={onChangeHandlerCond}
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        minWidth: '245px',
                      }),
                  
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: 'white', // Set background color for the dropdown list
                        color: 'black', // Set text color for the dropdown list
                      })
                    }}
                    options={condos}
                    value={lc}
                    isClearable
                    />
                    {/* <Input
                      id="fab-search"
                     
                      options={[condos, "condo"]}
                      value={lc.condo}
                      type="select"
                       onChange={onChangeCondo("condo")}
                      // onChange={e => { setLc("condo"); onChangeCondo("condo") }}
                      styles={{ width: "100%" }}
                    /> */}
                  </div>

              {/* <div className="col-6">
                <Input
                  id="fab-register"
                  label="Condomínio"
                  type="select"
                  options={[condos, "condo"]}
                  value={lc.condo}
                  onChange={onChangeCondo("condo")}
                  styles={{ width: "100%" }}
                />
              </div> */}
            </div>
               : null  }
            <div className="row" style={{ textAlign: "end" }}>

            <div className="col-6" style={{ textAlign: "start" }}>
                    <label
                      htmlFor="Condominio"
                      className="mt-1"
                    >
                      Fabricante Lora
                    </label>
                    <Select
                      // id="fab-search"
                      // className="form-control form-control-lg"
                      // label="Número Medidor"
                      // options={[makers, "maker"]}
                      // value={search.maker}
                      // type="select"
                      // onChange={onChangeHandler("nr")}
                      // styles={{ width: "100%" }}
                      label="Fabricante Lora"
                      id="inlineFormSelectPrefc"
                    placeholder="Selecione..."
                    onChange={onChangeHandlerMaker}
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        minWidth: '245px',
                      }),
                  
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: 'white', // Set background color for the dropdown list
                        color: 'black', // Set text color for the dropdown list
                      })
                    }}
                    options={makers}
                    value={maker}
                    isClearable
                    />
                    {/* <Input
                      id="fab-search"
                     
                      options={[condos, "condo"]}
                      value={lc.condo}
                      type="select"
                       onChange={onChangeCondo("condo")}
                      // onChange={e => { setLc("condo"); onChangeCondo("condo") }}
                      styles={{ width: "100%" }}
                    /> */}
                  </div>
              {/* <div className="col-6" style={{ textAlign: "start" }}>
                <Input
                  id="fab-search"
                  label="Fabricante Lora"
                  options={[makers, "maker"]}
                  value={search.maker}
                  type="select"
                  onChange={onChangeHandler("maker")}
                  styles={{ width: "100%" }}
                />
              </div> */}
              {DS_STATUS == 10 ?
              <div className="col-6" style={{ alignSelf: "center" }}>
                <Button
                  icon="ti-agenda"
                  text="Cadastrar"
                  link="/cadastro/modulo/equipamentolora-cadastro"
                />
              </div>
                : null} 
            </div>
            <div className="row">
              <div className="col-6">
                <Input
                  id="desc"
                  label="Descrição do Módulo"
                  value={search.desc}
                  onChange={onChangeHandler("desc")}
                  styles={{ width: "100%", minWidth: '245px' }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Input
                  id="model"
                  label="Modelo do Módulo"
                  value={search.model}
                  onChange={onChangeHandler("model")}
                  styles={{ width: "100%", minWidth: '245px' }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Input
                  id="number"
                  label="Número do Módulo (MAC ADDRESS)"
                  value={search.number}
                  onChange={onChangeHandler("number")}
                  styles={{ width: "100%", minWidth: '245px' }}
                />
              </div>
            </div>
            <div className="row">
              <div style={{ textAlign: "center", marginTop: "0.5rem",padding:"15px" }}>
                {/* <Button
                style={{ paddingLeft:"15px" }}
                  icon="ti-search"
                  text="Pesquisar"
                  action={submitHandler}
                  primaryColor="#5d4ec0"
                  hoverColor="#4eadc0"
                /> */}
                <Button
                style={{ paddingLeft:"15px" }}
                  icon="ti-search"
                  text="Pesquisar"
                  action={submitHandler}
                  primaryColor="#5d4ec0"
                  hoverColor="#4eadc0"
                />
              </div>
              
              
            </div>
          </div>
        </form>
        {/* <SearchTable
          tableHead={[
            "Fabricante Lora",
            "Descrição do Módulo",
            "Modelo Módulo",
            "Número Módulo",
            "",
          ]}
          tableBody={[values, 2]}
          showTable={showList}
        >
          {values?.map((value, key) => (
            <td key={key}>
              <Link
                type="button"
                to={`/cadastro/modulo/equipamentolora-update/${value.id}`}
                color="link"
                size="sm"
                className="btn-light waves-effect waves-light"
              >
                <i className="dripicons-document-edit" />{" "}
              </Link>
            </td>
          ))}
        </SearchTable> */}
        {showList && (
          <Container fluid>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th className="align-middle">Fabricante Lora</th>
                  <th className="align-middle">Descrição do Módulo</th>
                  <th className="align-middle">Modelo Módulo</th>
                  <th className="align-middle">Número Módulo</th>
                  
                  <th className="align-middle"></th>
                  <th className="align-middle"></th>
                  
                </tr>
              </thead>
              <tbody>
                {values?.map((value, key) => (
                  
                  <tr key={key}>
                  <td>{value.maker}</td>
                  <td>{value.desc}</td>
                  <td>{value.model}</td>
                  <td>{value.number}</td>

                  <td>
                  <i onClick={() => handleButtonClick(value.id)} className="dripicons-trash" style={{ cursor: 'pointer' }} /> 
                  </td>
                  
                  <td>
                    <Link
                      type="button"
                      to={`/cadastro/modulo/equipamentolora-update/${value.id}`}
                      color="link"
                      size="sm"
                      className="btn-light waves-effect waves-light"
                    >
                      <i className="dripicons-document-edit" />
                    </Link>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Container>
        
      )}
      {showPopup && (
                    <div className="modal-overlay">
                      <div className="modal-content2">
                        <p>Você realmente deseja deletar o módulo?</p>
                        <div className="modal-buttons">
                          <button className="confirm-button" onClick={handleConfirm}>Sim</button>
                          <button className="cancel-button" onClick={handleCancel}>Não</button>
                        </div>
                      </div>
                    </div>
                  )}
      </div>
    </React.Fragment>
  );
};

export default EquipamentoLora;
